function open_form()
{
    $("div#form").parent('div').removeClass("hidden").removeClass("hidden-xs");
    $("div#form-close").remove();
    $("html, body").animate({scrollTop: $('#form').offset().top});
    stickyButtonTrigger = 'div#form';
}

jQuery(document).ready(function ($) {
    stickyButtonTrigger = 'div#form-close > a.form-open';

    $('a[href="#form"]').click(function () {
        open_form();
        return false;
    });
    $("a.form-open,button.form-open").click(function () {
        open_form();
        return false;
    });

    $('#formModal').on('loaded.bs.modal', function (e) {
        $('div.modal-content').prepend('<div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button><h4 class="modal-title">&nbsp;</h4></div>');
    });

});