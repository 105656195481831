var phoneValidationFailedMessage = '<p class="help-block">Bitte geben Sie eine korrekte Rufnummer ein.</p>';

$('body').on('click', "button.button-add-phone", function () {
    $("i.icon-refresh").removeClass('hidden');
    var form_data = $('form#addPhone').serialize();
    $.ajax({
        url: "/leads/add_phone",
        data: form_data,
        type: "POST",
        dataType: "json",
        success: function (success) {
            if (typeof success.errors !== "undefined" && success.errors) {
                $("i.icon-refresh").addClass('hidden');
                $('input[name="data[Lead][phone]"]').parent('div').addClass('has-error');
                $('p.help-block').remove();
                $("div.input-group").after(phoneValidationFailedMessage);
            } else {
                addedPhoneFinal = '<h2>Vielen Dank für Ihre Anfrage!</h2><p class="success-message">Wir haben diese an unsere Experten zum Thema weitergeleitet, die sich in Kürze bei Ihnen melden werden. Selbstverständlich ist die komplette Beratung und Angebotserstellung <strong>kostenlos</strong> und unverbindlich.</p>';
                $('div#client-listing p').remove();
                if(typeof clientListingFinal !== "undefined") {
                    $('div#client-listing h2').replaceWith(clientListingFinal);
                } else {
                    $('div#client-listing h2').replaceWith(addedPhoneFinal);
                }

                $('form#addPhone').remove();
                if($("div.client-element address").length == 0) {
                    $('p.success-message').replaceWith(noClientsMessage);
                    $('div#client-listing h3').remove();
                }
            }
        }
    });
    return false;
});