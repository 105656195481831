$('.form-carousel').carousel({
    wrap: false,
    interval: false
});

function markError(element) {
    element.addClass('has-error');
    element.parent('div').prev('div').find('div.form-label > label').addClass('text-danger');
}

$('.form-carousel').on('slide.bs.carousel', function (e) {
    if($('#slogan').length > 0) {
        if($('#slogan').css('width') == $('#slogan').next('div').css('width')) { //hack to check for viewport
            $("html, body").scrollTop($('#form').offset().top);
        }
    }

    $('div.form-label > label').removeClass('text-danger');
    $('div.form-label > label').removeClass('text-danger');
    var error = 0;
    var fields = $("div.item.active").find('div.form-element.required').each(function(element) {
        $(this).removeClass('has-error');

        // radio
        if($(this).find('div.radio').length > 0) {
            var field = $(this).find('div.radio').find('input').attr('name');
            if($('input[name="'+ field +'"]').is(":checked") == false) {
                markError($(this));
                error = 1;
            }
        }

        // input
        if($(this).find('input[type="text"]').length > 0) {
            if($(this).find('input[type="text"]').val() == "") {
                markError($(this));
                error = 1;
            }
        }

        // checkbox
        if($(this).find('div.checkbox').length > 0) {
            if($(this).find('div.checkbox').find('input:checked').length == 0) {
                markError($(this));
                error = 1;
            }
        }

        // textarea
        if($(this).find('textarea').length > 0) {
            if($(this).find('textarea').val() == "") {
                markError($(this));
                error = 1;
            }
        }
    });

    if(error == 0) {
        var next = $(e.relatedTarget);
        var to = next.index();
        var last_item = ($('div.item').length) - 1;
        if (to === 0)
        {
            $('a[data-slide="prev"]').addClass("hidden");
            if($('a[data-target="#product-selector"]').length) {
                $('a[data-target="#product-selector"]').parent('div').removeClass("hidden");
            } else {
                $('#form-slide-control').removeClass('col-xs-9').addClass('col-xs-12');
            }
        } else {
            if (to === last_item) {
                $('a[data-slide="prev"]').addClass("hidden");
                $('a[data-slide="next"]').addClass("hidden");
                $('#step-personal-data').addClass('active');
                $('#step-details').removeClass('active');
            } else {
                $('a[data-slide="prev"]').removeClass("hidden");
                $('#form-slide-control').removeClass('col-xs-12').addClass('col-xs-9');
            }
            if(to === 1) {
                $('a[data-target="#product-selector"]').parent('div').addClass("hidden");
            }
        }
    } else {
        return false;
    }
});