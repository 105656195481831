var validationFailedMessage = '<div class="col-md-12 col-xs-12"><div class="alert alert-danger" role="alert">Bitte ergänzen Sie Ihre Angaben in den rot markierten Feldern.</div></div>';
var noClientsMessage = '<p style="margin-bottom:30px;">Wir haben diese an unsere Experten weitergeleitet, die sich in Kürze bei Ihnen melden werden. Selbstverständlich ist die komplette Beratung und Angebotserstellung kostenlos und unverbindlich.</p>';

$('form.lead-form').submit(function () {
    var count_clients_message = "";
    var phone_number = "";
    var adress_data = "";
    var contact_person = "";
    var count_clients = 0;

    $('div.alert-danger').parent('div').remove();
    var form_data = $(this).serialize();
    var form_id = $(this).attr('id');
    $("#spinner").show();
    $("#form-open").addClass('fade-out');
    $("#form-open").fadeOut(animationTimer, function() {
        $.ajax({
            url: "/leads/add/" + form_id.replace("LeadAdd",""),
            data: form_data,
            type: "POST",
            dataType: "json",
            success: function (success) {
                var clientListing = '<div class="row"><div class="form-element" id="client-listing"><div class="col-md-12 col-xs-12"><h2>Vielen Dank für Ihre Anfrage!</h2><p class="success-message">Wir haben diese an unsere Experten zum Thema ' + success.industry_title + ' weitergeleitet, die sich in Kürze bei Ihnen melden werden. Selbstverständlich ist die komplette Beratung und Angebotserstellung <strong>kostenlos</strong> und unverbindlich.</p><div class="experts"></div></div></div></div>';
                clientListingFinal = '<h2>Vielen Dank für Ihre Anfrage!</h2><p class="success-message">Wir haben diese an unsere Experten zum Thema ' + success.industry_title + ' weitergeleitet, die sich in Kürze bei Ihnen melden werden. Selbstverständlich ist die komplette Beratung und Angebotserstellung <strong>kostenlos</strong> und unverbindlich.</p><h3>Ihre Experten zum Thema ' + success.industry_title + '</h3>';

                if (typeof success.errors !== "undefined" && success.errors) {
                    $("#spinner").hide();
                    $("#form-open").fadeIn(1).removeClass('fade-out');
                    $("div").removeClass('has-error');

                    $.each(success.errors, function (value) {
                        if (value === 'privacy_policy') {
                            $('form#' + form_id).find('input[name="data[Lead][' + value + ']"]').parent('label').parent('div').addClass('has-error');
                        } else {
                            $('form#' + form_id).find('input[name="data[Lead][' + value + ']"]').parent('div').addClass('has-error');
                        }
                    });
                    $("div.address-label").after(validationFailedMessage);
                } else {
                    if(typeof dataLayer !== "undefined") {
                        if(typeof success.lead_data_one_dimension.campaign_id !== "undefined") {
                            dataLayer.push({'campaignId': success.lead_data_one_dimension.campaign_id});
                        }
                    }
                    $("#spinner").hide();
                    $("#form-open").removeClass('fade-out');
                    $('#step-result').addClass('active');
                    $('#step-personal-data').removeClass('active');
                    if (typeof success.clients !== "undefined" && success.clients) {
                        count_clients_message = 'Wir haben für Ihre Anfrage <strong><span class="count-clients">' + success.clients.length + '</span> passende Anbieter</strong> gefunden und diese weitergeleitet.';
                    } else {
                        count_clients_message = '';
                    }
                    if(typeof dataLayer !== "undefined") {
                        dataLayer.push({'revenue': success.revenue});
                        dataLayer.push({'leadId': success.lead_id});
                    }
                    if($('form#' + form_id).find('input#LeadPhone').length) {
                        phone_number = $('input#LeadPhone').val();
                        if(phone_number === "") {
                            $.get("/leads/get_phone_form/" + success.lead_id, function(phone_form) {
                                $('#form-open').replaceWith(phone_form);
                                $('#client-listing').find('p').prepend(count_clients_message + ' ');
                                $('input[name="data[Lead][id]"]').val(success.lead_id);
                            });
                        } else {
                            $('#form-open').replaceWith(clientListing);
                        }
                    } else {
                        $.get("/leads/get_phone_form/" + success.lead_id, function(phone_form) {
                            $('#form-open').replaceWith(phone_form);
                            $('#client-listing').find('p').prepend(count_clients_message + ' ');
                            $('input[name="data[Lead][id]"]').val(success.lead_id);
                        });
                    }
                    $('#form-body').attr('id','form-success');
                    if (typeof success.clients !== "undefined" && success.clients) {
                        $('div.experts').replaceWith('<h3>Ihre Experten zum Thema ' + success.industry_title + '</h3>');
                        $.each(success.clients, function (key, client) {
                            if(client.Client.hidden !== true) {
                                if(typeof client.Address[0] !== "undefined") {
                                    if(client.Address[0].street !== "" && client.Address[0].zipcode !== "" && client.Address[0].city !== "") {
                                        adress_data = client.Address[0].street + ', ' + client.Address[0].zipcode + ' ' + client.Address[0].city  + '<br/>';
                                    } else {
                                        adress_data = "";
                                    }
                                } else {
                                    adress_data = "";
                                }
                                if(typeof client.Address[0] !== "undefined") {

                                    count_clients = count_clients + 1;
                                    if(client.Address[0].contact_person !== null && client.Address[0].contact_person !== "") {
                                        contact_person = client.Address[0].contact_person + '<br/>';
                                    } else {
                                        contact_person = '';
                                    }
                                    $('div#form-success').append('<hr /><div class="row client-element"><div class="col-xs-12 col-md-12"><img src="' + client.Client.logo_url + '" class="thumbnail pull-left"><address><strong>' + client.Client.name + '</strong><br/>' + contact_person + adress_data + 'Tel: ' + client.Address[0].phone + '<br/><a href="mailto:' + client.Address[0].email + '">' + client.Address[0].email + '</a></address></div></div>');
                                    $('span.count-clients').text(count_clients);
                                }
                            }
                        });

                        if($(".client-element").length == 0) {
                            $("p.success-message").next("h3").remove();
                        }

                        if(typeof dataLayer !== "undefined") {
                            dataLayer.push({'clients': success.clients.length});
                            dataLayer.push({'leadRevenue': success.revenue});
                            dataLayer.push({'event': 'lead'});
                        }

                    } else {
                        if($('input#phone').length == 0) {
                            $('p.success-message').replaceWith(noClientsMessage);
                        }
                        if(typeof dataLayer !== "undefined") {
                            dataLayer.push({'clients': 0});
                            dataLayer.push({'leadRevenue': 0});
                            dataLayer.push({'event': 'lead'});
                        }
                    }
                }
            }
        });
    });
return false;
});
