function jumpToForm(form_id) {
    $('div#form-carousel-' + form_id).removeClass('hidden');
    $('div[data-form-id][data-form-id!="'+form_id+'"]').remove();
    $('div#product-selector').remove();
    $('#form-slide-control').removeClass('col-xs-12').addClass('col-xs-9');
}

function jumpToSelector() {
    $('div#form-carousel-' + form_id).removeClass('hidden');
    $('div[data-form-id][data-form-id!="'+form_id+'"]').remove();
    $('div#product-selector').remove();
}

// Product Selector / Multiple forms
$('a.jump-to-form').on('click', function() {
    var form_id = $(this).attr('data-form-target');
    jumpToForm(form_id);
    return false;
});

$('a.select-product').on('click', function() {
	var form_id = $('input[name="productSelection"]:checked').val();
	jumpToForm(form_id);
	return false;
});

$('a[data-target="#product-selector"]').on('click', function() {
	location.reload();
	return false;
});